<template>
    <f7-sheet
        :opened="shown"
        class="restaurant-product-popup"
        backdrop
        swipe-to-close
        swipe-handler=".restaurant-product-popup__picture"
        close-by-backdrop-click
        close-by-outside-click
        @sheet:closed="closeSheet()"
    >
        <div class="custom-sheet-product__close-button" @click="closeSheet()"></div>
        <div v-if="loading" class="preloader-wrapper preloader-wrapper__full-height">
            <f7-preloader size="26" />
        </div>
        <div v-show="!loading">
            <div class="restaurant-product-popup__container">
                <div class="restaurant-product-popup__picture__wrapper">
                    <div
                        class="restaurant-product-popup__picture" 
                        :style="imageSrc(product.picture) ? `background-image: url(${ imageSrc(product.picture) })` : ''">
                        <!-- <img v-if="imageSrc(product.picture)" :src="imageSrc(product.picture)" />
                        <EmptyImg v-else service-type="restaurant" /> -->
                        <!-- <div :class="`restaurant-product-popup__discount gradient-${ BUSINESS_TYPE.RESTAURANT }`" v-if="product.discount">
                            {{ product.discount }}%
                        </div> -->
                    </div>
                </div>

                <div class="restaurant-product-popup__content">
                    <div>
                        <div class="restaurant-product-popup__title">
                            <span v-html="productTitle"></span>
                            <div v-if="product.price" class="restaurant-product-popup__price">
                                <span>{{ priceFormat(product.price) }}&nbsp;Р</span>
                                <div v-if="product.old_price" class="restaurant-product-popup__old-price">
                                    <span>{{ priceFormat(product.old_price) }}&nbsp;Р</span>
                                    <div class="restaurant-product-popup__old-price__line"></div>
                                </div>
                                <span v-if="!entity.counting_calories" class="restaurant-product-popup__weight">{{ weigth }}</span>
                            </div>
                        </div>

                        <div v-if="!!entity.counting_calories" class="nutrition-facts">
                            <div class="nutrition-fact">
                                <span style="font-weight: 500">{{ all_weight }}</span>
                                <span style="color: #9599A4">грамм</span>
                            </div>
                            <div class="nutrition-fact">
                                <span style="font-weight: 500">{{ all_calories }}</span>
                                <span style="color: #9599A4">ккал</span>
                            </div>
                            <div class="nutrition-fact">
                                <span style="font-weight: 500">{{ all_protein }}</span>
                                <span style="color: #9599A4">белки</span>
                            </div>
                            <div class="nutrition-fact">
                                <span style="font-weight: 500">{{ all_fat }}</span>
                                <span style="color: #9599A4">жиры</span>
                            </div>
                            <div class="nutrition-fact">
                                <span style="font-weight: 500">{{ all_carbohydrates }}</span>
                                <span style="color: #9599A4">углеводы</span>
                            </div>
                        </div>
                        
                        <hr v-if="product.modifiers && product.modifiers.length" class="hr-line" />

                        <div v-if="product.modifiers && product.modifiers.length">
                            <div class="restaurant-product-popup__list__title">На выбор</div>
                            <f7-list class="restaurant-product-popup__list">
                                <f7-list-item
                                    v-for="(modifier, index) in product.modifiers"
                                    :key="index"
                                    :class="`checkbox-list__item checkbox-gradient-${ BUSINESS_TYPE.RESTAURANT }`"
                                    radio
                                    radio-icon="checkbox"
                                    name="modifier"
                                    :value="index"
                                    :checked="index == 0"
                                    @change="setModifiers"
                                >
                                    <span class="restaurant-product-popup__list__label">{{ modifier.title }}
                                        <span 
                                            v-if="!!entity.counting_calories"
                                            :class="{'new-line': modifier.title.length > 8}"
                                            class="product_details_calories">
                                            {{ modifier.weight }}г&nbsp;{{ modifier.calories }}к&nbsp;/{{ modifier.protein }}б&nbsp;/{{ modifier.fat }}ж&nbsp;/{{ modifier.carbohydrates }}у
                                        </span>
                                    </span>
                                    <div v-if="modifier.price" class="restaurant-product-popup__prices">
                                        <span class="restaurant-product-popup__list__price restaurant-product-popup__list__price__one">+{{ modifier.price }} &nbsp;Р</span>
                                        <!-- <div v-if="modifier.old_price" class="restaurant-product-popup__old-price">
                                            <span class="restaurant-product-popup__list__price">+{{ priceFormat(modifier.old_price) }}&nbsp;Р</span>
                                            <div class="restaurant-product-popup__old-price__line"></div>
                                        </div> -->
                                    </div>
                                </f7-list-item>
                            </f7-list>
                        </div>

                        <hr v-if="product.modifierGroups && product.modifierGroups.length" class="hr-line" />

                        <div v-if="product.modifierGroups && product.modifierGroups.length">
                            <div v-for="(group, ind) in product.modifierGroups" :key="group.id">
                                <div class="restaurant-product-popup__list__title">{{ group.title }}</div>
                                <f7-list class="restaurant-product-popup__list">
                                    <f7-list-item
                                        v-for="(modifier, index) in group.modifiers"
                                        :key="index"
                                        :class="`checkbox-list__item checkbox-gradient-${ BUSINESS_TYPE.RESTAURANT }`"
                                        radio
                                        radio-icon="checkbox"
                                        :name="`${group.title}-modifier`"
                                        :value="`${ind},${index}`"
                                        :checked="index == 0"
                                        @change="setGroupModifiers"
                                    >
                                        <span class="restaurant-product-popup__list__label">{{ modifier.title }}
                                            <span 
                                                v-if="!!entity.counting_calories"
                                                :class="{'new-line': modifier.title.length > 8}"
                                                class="product_details_calories">
                                                {{ modifier.weight }}г&nbsp;{{ modifier.calories }}к&nbsp;/{{ modifier.protein }}б&nbsp;/{{ modifier.fat }}ж&nbsp;/{{ modifier.carbohydrates }}у
                                            </span>
                                        </span>
                                        <span class="restaurant-product-popup__list__price">+{{ modifier.price }}&nbsp;Р</span>
                                    </f7-list-item>
                                </f7-list>
                                <hr v-if="ind !== (product.modifierGroups.length - 1)" class="hr-line" />
                            </div>
                        </div>

                        <hr v-if="product.ingredients && product.ingredients.length" class="hr-line" />

                        <div v-if="entity.multiple_ingridients && product.ingredients && product.ingredients.length">
                            <div class="restaurant-product-popup__list__title">Добавить</div>
                            <f7-list class="restaurant-product-popup__list">
                                <f7-list-item
                                    v-for="(ingredient, index) in product.ingredients"
                                    :key="index"
                                    :disabled="ingredient.disabled"
                                    :class="`checkbox-list__item checkbox-gradient-${ BUSINESS_TYPE.RESTAURANT } ${ingredient.disabled ? 'disabled' : ''}`"
                                    name="ingredient"
                                    :value="index"
                                    @change="setIngredients"
                                >

                                    <div class="restaurant-product-popup-page__list-item">
                                        <div>
                                            <span class="restaurant-product-popup__list__label">{{ ingredient.title }}&nbsp;
                                                <span
                                                    v-if="!!entity.counting_calories" 
                                                    :class="{'new-line': ingredient.title.length > 8}"
                                                    class="product_details_calories">
                                                    {{ ingredient.weight }}г&nbsp;{{ ingredient.calories }}к&nbsp;/{{ ingredient.protein }}б&nbsp;/{{ ingredient.fat }}ж&nbsp;/{{ ingredient.carbohydrates }}у
                                                </span>
                                            </span>
                                        </div>

                                        <div class="right-side">
                                            <div v-if="ingredient.price" class="restaurant-product-popup__prices__start">
                                                <span class="restaurant-product-popup__list__price restaurant-product-popup__list__price__one">+{{ ingredient.price }} &nbsp;Р</span>
                                                <!-- <div v-if="ingredient.old_price" class="restaurant-product-popup__old-price">
                                            <span class="restaurant-product-popup__list__price">+{{ priceFormat(ingredient.old_price) }}&nbsp;Р</span>
                                            <div class="restaurant-product-popup__old-price__line"></div>
                                        </div> -->
                                            </div>&nbsp;&nbsp;
                                            <f7-stepper
                                                class="rounded-md bg-gray-100 h-7 stepper-small"
                                                :value="ingredient.quantity"
                                                :min="0"
                                                input-readonly
                                                @stepper:change="updateIngredientQuantity($event, index, ingredient)"
                                            />
                                        </div>
                                    </div>

                                </f7-list-item>
                            </f7-list>
                        </div>

                        <div v-if="!entity.multiple_ingridients && product.ingredients && product.ingredients.length">
                            <div class="restaurant-product-popup__list__title">Добавить</div>
                            <f7-list class="restaurant-product-popup__list">
                                <f7-list-item
                                    v-for="(ingredient, index) in product.ingredients"
                                    :key="index"
                                    checkbox
                                    :disabled="ingredient.disabled"
                                    :class="`checkbox-list__item checkbox-gradient-${ BUSINESS_TYPE.RESTAURANT } ${ingredient.disabled ? 'disabled' : ''}`"
                                    name="ingredient"
                                    :value="index"
                                    @change="setIngredients"
                                >
                                    <span class="restaurant-product-popup__list__label">{{ ingredient.title }} 
                                        <span 
                                            v-if="!!entity.counting_calories"
                                            :class="{'new-line': ingredient.title.length > 8}"
                                            class="product_details_calories">
                                            {{ ingredient.weight }}г&nbsp;{{ ingredient.calories }}к&nbsp;/{{ ingredient.protein }}б&nbsp;/{{ ingredient.fat }}ж&nbsp;/{{ ingredient.carbohydrates }}у
                                        </span>
                                    </span>
                                    <div v-if="ingredient.price" class="restaurant-product-popup__prices">
                                        <span class="restaurant-product-popup__list__price restaurant-product-popup__list__price__one">+{{ ingredient.price }} &nbsp;Р</span>
                                        <!-- <div v-if="ingredient.old_price" class="restaurant-product-popup__old-price">
                                            <span class="restaurant-product-popup__list__price">+{{ priceFormat(ingredient.old_price) }}&nbsp;Р</span>
                                            <div class="restaurant-product-popup__old-price__line"></div>
                                        </div> -->
                                    </div>
                                </f7-list-item>
                            </f7-list>
                        </div>

                        <hr v-if="product.ingredientGroups && product.ingredientGroups.length" class="hr-line" />

                        <div v-if="entity.multiple_ingridients && product.ingredientGroups && product.ingredientGroups.length">
                            <div v-for="(group, ind) in product.ingredientGroups" :key="group.id">
                                <div class="restaurant-product-popup__list__title">{{ group.title }}</div>
                                <f7-list class="restaurant-product-popup__list">
                                    <f7-list-item
                                        v-for="(ingredient, index) in group.ingredients"
                                        :key="index"
                                        :class="`checkbox-list__item checkbox-gradient-${ BUSINESS_TYPE.RESTAURANT } ${ingredient.disabled ? 'disabled' : ''}`"
                                        :disabled="ingredient.disabled"
                                        :name="`${group.title}-ingredient`"
                                        :value="`${ind},${index}`"
                                        @change="setGroupIngredients"
                                    >
                                        <div class="restaurant-product-popup-page__list-item">
                                            <div>
                                                <span class="restaurant-product-popup__list__label">{{ ingredient.title }}
                                                    <span 
                                                        v-if="!!entity.counting_calories"
                                                        :class="{'new-line': ingredient.title.length > 8}"
                                                        class="product_details_calories">
                                                        {{ ingredient.weight }}г&nbsp;{{ ingredient.calories }}к&nbsp;/{{ ingredient.protein }}б&nbsp;/{{ ingredient.fat }}ж&nbsp;/{{ ingredient.carbohydrates }}у
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="right-side">
                                                <div v-if="ingredient.price" class="restaurant-product-popup__prices__start">
                                                    <span class="restaurant-product-popup__list__price restaurant-product-popup__list__price__one">+{{ ingredient.price }} &nbsp;Р</span>
                                                    <!-- <div v-if="ingredient.old_price" class="restaurant-product-popup__old-price">
                                                <span class="restaurant-product-popup__list__price">+{{ priceFormat(ingredient.old_price) }}&nbsp;Р</span>
                                                <div class="restaurant-product-popup__old-price__line"></div>
                                            </div> -->
                                                </div>&nbsp;&nbsp;
                                                <!-- <div class="stepper-container"> -->
                                                <f7-stepper
                                                    class="rounded-md bg-gray-100 h-7"
                                                    :value="ingredient.quantity"
                                                    :min="0"
                                                    input-readonly
                                                    @stepper:change="updateIngredientQuantity($event, index, ingredient, group)"
                                                />
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </f7-list-item>
                                </f7-list>
                                <hr v-if="ind !== (product.ingredientGroups.length - 1)" class="hr-line" />
                            </div>
                        </div>

                        <div v-if="!entity.multiple_ingridients && product.ingredientGroups && product.ingredientGroups.length">
                            <div v-for="(group, ind) in product.ingredientGroups" :key="group.id">
                                <div class="restaurant-product-popup__list__title">{{ group.title }}</div>
                                <f7-list class="restaurant-product-popup__list">
                                    <f7-list-item
                                        v-for="(ingredient, index) in group.ingredients"
                                        :key="index"
                                        :class="`checkbox-list__item checkbox-gradient-${ BUSINESS_TYPE.RESTAURANT } ${ingredient.disabled ? 'disabled' : ''}`"
                                        checkbox
                                        :disabled="ingredient.disabled"
                                        :name="`${group.title}-ingredient`"
                                        :value="`${ind},${index}`"
                                        @change="setGroupIngredients"
                                    >
                                        <span class="restaurant-product-popup__list__label">{{ ingredient.title }}
                                            <span 
                                                v-if="!!entity.counting_calories" 
                                                :class="{'new-line': ingredient.title.length > 8}"
                                                class="product_details_calories">
                                                {{ ingredient.weight }}г&nbsp;{{ ingredient.calories }}к&nbsp;/{{ ingredient.protein }}б&nbsp;/{{ ingredient.fat }}ж&nbsp;/{{ ingredient.carbohydrates }}у
                                            </span>
                                        </span>
                                        <span class="restaurant-product-popup__list__price">+{{ ingredient.price }}&nbsp;Р</span>
                                    </f7-list-item>
                                </f7-list>
                                <hr v-if="ind !== (product.ingredientGroups.length - 1)" class="hr-line" />
                            </div>
                        </div>

                        <hr v-if="!entity.counting_calories && productDescription" class="hr-line" />

                        <div v-if="!entity.counting_calories" class="restaurant-product-popup__description">
                            <span v-if="productDescription" class="restaurant-product-popup__description__item">
                                <ShowHideText :text="productDescription" />
                            </span>
                        </div>
                        
                        <hr v-if="composition" class="hr-line" />

                        <div class="restaurant-product-popup__description">
                            <span v-if="composition && entity.show_additional_info" class="restaurant-product-popup__description__item">
                                <ShowHideText :text="`<b>Состав</b>: ${ composition }`" />
                            </span>
                        </div>
                        
                        <hr v-if="allergens" class="hr-line" />

                        <div class="restaurant-product-popup__description">
                            <span v-if="allergens" class="restaurant-product-popup__description__item">
                                <ShowHideText :text="`<b>Аллергены</b>: ${ allergens }`" />
                            </span>
                        </div>

                        <hr v-if="!entity.counting_calories && descriptionNumbers" class="hr-line" />

                        <div
                            v-if="!entity.counting_calories && descriptionNumbers"
                            class="restaurant-product-popup__description__numbers"
                            v-html="descriptionNumbers"></div>
                    </div>
                </div>
            </div>
            
            <div class="restaurant-product-popup__add-to-cart">
                <f7-stepper
                    v-if="product.id"
                    :value="quantity"
                    :min="1"
                    input-readonly
                    @stepper:change="setQuantity"
                />
                <button
                    v-if="product.id"
                    :class="`custom-button restaurant-product-popup__add-to-cart__button gradient-${ BUSINESS_TYPE.RESTAURANT }`"
                    @click="addToCart">
                    <span>Добавить</span>
                    <span>{{ priceFormat(finialPrice) }}&nbsp;Р</span>
                </button>
            </div>
        </div>
    </f7-sheet>
</template>
<script>
    import { mapState } from 'vuex';
    import store from '@/store';
    import { BUSINESS_TYPE, ORDER_TYPE, UNITS_ENUM } from '@/js/utils/vars';
    import { addItemToCart, sendAppMetricaEvent, priceFormat } from '@/js/utils/service-helpers';

    import AppPage from '@/components/common/AppPage';
    import EmptyImg from '@/components/common/EmptyImg';
    import ShowHideText from '@/components/common/ShowHideText'

    export default {
        components: {
            AppPage,
            EmptyImg,
            ShowHideText
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            entityCode: {
                type: String,
                default: ''
            },
            productId: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            priceFormat,
            BUSINESS_TYPE,
            ORDER_TYPE,
            quantity: 1,
            item_price: 0,
            old_item_price: 0,
            finialPrice: 0,
            loading: false,
            shown: false,

            all_carbohydrates: 0,
            all_fat: 0,
            all_protein: 0, 
            all_weight: 0,
            all_calories: 0,
        }),
        computed: {
            ...mapState('restaurants', ['entity', 'product']),
            ...mapState('cart', ['items', 'orderType']),
            ...mapState('partners', {
                partnersList: state => state.list
            }),
            productTitle() {
                return this.product.title ? this.product.title.trim() : '';
            },
            productDescription() {
                return this.product.description ? this.product.description.replace(/\n/g, '<br>').trim() : '';
            },
            weigth() {
                if(this.product.weight) {
                    return `${ this.product.weight } ${UNITS_ENUM[this.product.units]}`;
                }
                return '';
            },
            composition() {
                return this.product.composition ? this.product.composition.trim() : '';
            },
            allergens() {
                return this.product.allergens ? this.product.allergens.trim() : '';
            },
            descriptionNumbers() {
                const numbers = [];
                if(this.product.quantity) {
                    numbers.push(`<div class="restaurant-product-popup__description__numbers__item">Количество<br><span>${ this.product.quantity } шт</span></div>`);
                }
                if(this.product.calories) {
                    numbers.push(`<div class="restaurant-product-popup__description__numbers__item">Пищевая ценность<br><span>${ this.product.calories } ккал${ this.entity.calories_100g ? '/100 г' : '' }</span></div>`);
                }
                return numbers.join('');
            },
        },
        watch: {
            productId: {
                deep: true,
                async handler() {
                    if(this.productId) {
                        this.loadProduct();
                        this.shown = true;
                        this.quantity = 1;
                    }
                }
            },
            show: {
                deep: true,
                async handler() {
                    if(this.productId && !this.loading) {
                        this.shown = this.show;

                        if(this.show) {
                            sendAppMetricaEvent('PRODUCT_OPENED', {
                                Service: 'restaurant',
                                ServiceCode: this.entityCode,
                                ProductId: this.productId,
                                InSheet: true
                            });
                        }
                    }
                }
            }
        },
        async mounted() {
            if(this.productId) {
                this.loadProduct();
                this.shown = true;
            }
        },
        methods: {
            updateIngredientQuantity(index, event, ingridient, group) {
                if (group && this.product?.ingredientGroups?.length > 0) {
                    for (const gr of this.product?.ingredientGroups) {
                        if (gr?.id === group?.id) {
                            for (const ingr of group?.ingredients) {
                                if (ingr?.id === ingridient?.id) {
                                    ingr.quantity = +index;
                                }
                            }
                        }
                    }
                } else {
                    if (this.product?.ingredients?.length > 0) {
                        for (const ingr of this.product?.ingredients) {
                            if (ingr?.id === ingridient?.id) {
                                ingr.quantity = +index;
                            }
                        }
                    }
                }

                this.recalculateItemPrice();
            },
            calculateItemCalories() {
                if (!this.entity?.counting_calories) {
                    return;
                }

                const item = this.product;

                this.all_calories = item?.calories || 0;
                this.all_carbohydrates = item?.carbohydrates || 0;
                this.all_fat = item?.fat || 0;
                this.all_weight = item?.weight || 0;
                this.all_protein = item?.protein || 0;
            },

            calculateIngridientAndModifiedCalories() {
                if (!this.entity?.counting_calories) {
                    return;
                }
                const multipleIngredients = this.entity?.multiple_ingridients || false;

                const product = this.product;
                const productQuantity = this.quantity > 0 ? this.quantity : 1;

                let allCalories = product?.calories || 0;
                let allCarbohydrates = product?.carbohydrates || 0;
                let allFat = product?.fat || 0;
                let allWeight = product?.weight || 0;
                let allProtein = product?.protein || 0;

                const ingredients = product?.ingredients || []; 
                const ingredientGroups = product?.ingredientGroups || [];
                
                const modifiers = product?.modifiers || [];
                const modifierGroups = product?.modifierGroups || []; 

                if (ingredients?.length > 0) {
                    for (const ingridient of ingredients) {
                        if (!!multipleIngredients && ingridient?.quantity > 0) {
                            allCalories = ingridient?.calories ? allCalories + (ingridient?.calories * ingridient?.quantity) : allCalories;
                            allCarbohydrates = ingridient?.carbohydrates ? allCarbohydrates + (ingridient?.carbohydrates * ingridient?.quantity) : allCarbohydrates;
                            allFat = ingridient?.fat ? allFat + (ingridient?.fat * ingridient?.quantity) : allFat;
                            allWeight = ingridient?.weight ? allWeight + (ingridient?.weight * ingridient?.quantity) : allWeight;
                            allProtein = ingridient?.protein ? allProtein + (ingridient?.protein * ingridient?.quantity) : allProtein;
                        } else if (ingridient?.checked === true) {
                            allCalories = ingridient?.calories ? allCalories + ingridient?.calories : allCalories;
                            allCarbohydrates = ingridient?.carbohydrates ? allCarbohydrates + ingridient?.carbohydrates : allCarbohydrates;
                            allFat = ingridient?.fat ? allFat + ingridient?.fat : allFat;
                            allWeight = ingridient?.weight ? allWeight + ingridient?.weight : allWeight;
                            allProtein = ingridient?.protein ? allProtein + ingridient?.protein : allProtein;
                        }
                    }
                }
                if (ingredientGroups?.length > 0) {
                    for (const group of ingredientGroups) {
                        for (const ingridient of group?.ingredients) {
                            if (!!multipleIngredients && ingridient?.quantity > 0) {
                                allCalories = ingridient?.calories ? allCalories + (ingridient?.calories * ingridient?.quantity) : allCalories;
                                allCarbohydrates = ingridient?.carbohydrates ? allCarbohydrates + (ingridient?.carbohydrates * ingridient?.quantity) : allCarbohydrates;
                                allFat = ingridient?.fat ? allFat + (ingridient?.fat * ingridient?.quantity) : allFat;
                                allWeight = ingridient?.weight ? allWeight + (ingridient?.weight * ingridient?.quantity) : allWeight;
                                allProtein = ingridient?.protein ? allProtein + (ingridient?.protein * ingridient?.quantity) : allProtein;
                            } else if (ingridient?.checked === true) {
                                allCalories = ingridient?.calories ? allCalories + ingridient?.calories : allCalories;
                                allCarbohydrates = ingridient?.carbohydrates ? allCarbohydrates + ingridient?.carbohydrates : allCarbohydrates;
                                allFat = ingridient?.fat ? allFat + ingridient?.fat : allFat;
                                allWeight = ingridient?.weight ? allWeight + ingridient?.weight : allWeight;
                                allProtein = ingridient?.protein ? allProtein + ingridient?.protein : allProtein;
                            }
                        }
                    }
                }
                if (modifiers?.length > 0) {
                    for (const modifier of modifiers) {
                        if (modifier?.checked === true) {
                            allCalories = modifier?.calories ? allCalories + modifier?.calories : allCalories;
                            allCarbohydrates = modifier?.carbohydrates ? allCarbohydrates + modifier?.carbohydrates : allCarbohydrates;
                            allFat = modifier?.fat ? allFat + modifier?.fat : allFat;
                            allWeight = modifier?.weight ? allWeight + modifier?.weight : allWeight;
                            allProtein = modifier?.protein ? allProtein + modifier?.protein : allProtein;
                        }
                    }
                }
                if (modifierGroups?.length > 0) {
                    for (const group of modifierGroups) {
                        for (const modifier of group?.modifiers) {
                            if (modifier?.checked === true) {
                                allCalories = modifier?.calories ? allCalories + modifier?.calories : allCalories;
                                allCarbohydrates = modifier?.carbohydrates ? allCarbohydrates + modifier?.carbohydrates : allCarbohydrates;
                                allFat = modifier?.fat ? allFat + modifier?.fat : allFat;
                                allWeight = modifier?.weight ? allWeight + modifier?.weight : allWeight;
                                allProtein = modifier?.protein ? allProtein + modifier?.protein : allProtein;
                            }
                        }
                    }
                }

                this.all_calories = allCalories * productQuantity;
                this.all_carbohydrates = allCarbohydrates * productQuantity;
                this.all_fat = allFat * productQuantity;
                this.all_weight = allWeight * productQuantity;
                this.all_protein = allProtein * productQuantity;
            },
            async loadProduct() {
                this.loading = true;
                if(!this.entity || !this.entity.id || this.entity.code !== this.entityCode) {
                    await store.dispatch('restaurants/getByCode', {
                        restaurantCode: this.entityCode,
                    });
                }
                await store.dispatch('restaurants/getProduct', {
                    restaurantId: this.entity.id,
                    productId: this.productId
                });
                if(this.product.modifiers && this.product.modifiers.length) {
                    this.product.modifiers[0].checked = true;
                }
                if (this.product.ingredients && this.product.ingredients.length) {
                    this.product.ingredients = this.product.ingredients.map(ingridient => ({ ...ingridient, disabled: false }));
                }
                if (this.product?.ingredientGroups?.length) {
                    this.product.ingredientGroups = this.product.ingredientGroups.filter(group => (
                        group.ingredients && group.ingredients.length)
                    )
                }
                if (this.product?.modifierGroups?.length) {
                    this.product.modifierGroups = this.product.modifierGroups.filter(group => (
                        group.modifiers && group.modifiers.length)
                    ).map(group => {
                        if (group.modifiers) {
                            group.modifiers = group.modifiers.map((modifier, ind) => (ind === 0 ? { ...modifier, checked: true } : modifier));
                        }
                        return group;
                    });
                }
                this.calculateItemCalories(); 
                this.recalculateItemPrice();
                this.loading = false;

                sendAppMetricaEvent('PRODUCT_OPENED', {
                    Service: 'restaurant',
                    ServiceCode: this.entityCode,
                    ProductId: this.productId,
                    InSheet: true
                });
            },
            closeSheet() {
                this.shown = false;
                this.$emit('close');
                window.dispatchEvent(new Event('resize'));
            },
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/xl/${ picture }` : '';
            },
            setQuantity: function (value) {
                this.quantity = +value;
                this.recalculateItemPrice();
            },
            setModifiers: function (event) {
                for(let i=0; i < this.product.modifiers.length; i++) {
                    this.product.modifiers[i].checked = false;
                }
                this.product.modifiers[event.target.value].checked = true;
                this.recalculateItemPrice();
            },
            setGroupModifiers: function (event) {
                const [group, modifier] = event.target.value.split(',');
                this.product.modifierGroups[+group].modifiers = this.product.modifierGroups[+group].modifiers.map(modifier => {
                    modifier.checked = false;
                    return modifier;
                });
                this.product.modifierGroups[+group].modifiers[+modifier].checked = true;
                this.recalculateItemPrice();
            },
            setGroupIngredients: function (event) {
                const [group, ingredient] = event.target.value.split(',');
                this.product.ingredientGroups[+group].ingredients[+ingredient].checked = event.target.checked;

                if (this?.product?.ingredientGroups?.length) {
                    this.product.ingredientGroups = this.product.ingredientGroups.map(group => {
                        if (!group?.limit) {
                            return group;
                        }
                        const checkedIngridients = group?.ingredients?.filter(el => el.checked)?.length;

                        group.ingredients = group.ingredients.map(ingridient => {
                            if (checkedIngridients === group.limit) {
                                if (!ingridient.checked) {
                                    ingridient.disabled = true;
                                }
                            } else {
                                ingridient.disabled = false;
                            }
        
                            return ingridient;
                        });

                        return group;
                    });
                }

                this.recalculateItemPrice();
            },
            setIngredients: function (event) {
                this.product.ingredients[event.target.value].checked = event.target.checked;

                if (this.product.ingridients_limit) {
                    this.product.ingredients = this.product.ingredients.map(ingridient => {
                        const checkedIngridients = this.product.ingredients.filter(el => el.checked).length;
    
                        if (checkedIngridients === this.product.ingridients_limit) {
                            if (!ingridient.checked) {
                                ingridient.disabled = true;
                            }
                        } else {
                            ingridient.disabled = false;
                        }
    
                        return ingridient;
                    });
                }

                this.recalculateItemPrice();
            },
            recalculateItemPrice() {
                let item_price = +this.product.price;
                let old_item_price = +this.product.old_price;
                for(let i=0; i < this.product.modifiers.length; i++) {
                    if(this.product.modifiers[i].checked) {
                        item_price += +this.product.modifiers[i].price
                        old_item_price += +this.product.modifiers[i].old_price
                    }
                }

                for(let i=0; i < this.product.ingredients.length; i++) {
                    if(this.product.ingredients[i]?.checked || this.product.ingredients[i].quantity > 0) {
                        const quantity = this.product.ingredients[i]?.checked ? 1 : this.product.ingredients[i].quantity;
                        item_price += +this.product.ingredients[i].price * +quantity
                        old_item_price += +this.product.ingredients[i].old_price * +quantity
                    }
                }

                if (this.product?.modifierGroups?.length) {
                    for (var i = 0; i < this.product.modifierGroups.length; i++) {
                        const group = this.product.modifierGroups[i];
                        for (var j = 0; j < group.modifiers.length; j++) {
                            if(group.modifiers[j].checked) {
                                item_price += +group.modifiers[j].price;
                                old_item_price += +group.modifiers[j].old_price;
                            }
                        }
                    }
                }
                if (this.product?.ingredientGroups?.length) {
                    for (var q = 0; q < this.product.ingredientGroups.length; q++) {
                        const group = this.product.ingredientGroups[q];
                        for (var w = 0; w < group.ingredients.length; w++) {
                            if(group.ingredients[w].checked || group.ingredients[w].quantity > 0) {
                                const quantity = group.ingredients[w].checked ? 1 : group.ingredients[w].quantity;
                                item_price += +group.ingredients[w].price * +quantity;
                                old_item_price += +group.ingredients[w].old_price * +quantity;
                            }
                        }
                    }
                }
                const finalPrice = this.quantity * item_price;
                this.finialPrice = ((finalPrice ^ 0) === finalPrice)
                    ? finalPrice
                    : (finalPrice).toFixed(2);
                this.item_price = item_price;
                this.old_item_price = old_item_price;

                this.calculateIngridientAndModifiedCalories();
            },
            addToCart: function () {
                let paymentType = 2;
                if (this.product.restaurant && this.product.restaurant.partner) {
                    paymentType = this.product.restaurant.partner.payment;
                }

                if (this.product.ingredients) {
                    this.product.ingredients = this.product.ingredients.map(ingredient => {
                        delete ingredient.disabled;
                        return ingredient;
                    });
                }

                if(this.product.modifiers && this.product.modifiers.length && this.product.modifiers.filter(modifier => modifier.checked).length === 0) {
                    this.product.modifiers[0].checked = true;
                }

                const ingredientGroupsDescription = this?.product?.ingredientGroups?.map(group => {
                    return group?.ingredients?.filter(ingredient => ingredient.checked || ingredient.quantity > 0)?.map(ingredient => `${ingredient?.quantity}x ${ingredient.title}`);
                }) || [];
                
                const description = [].concat(
                    this.product.modifiers.filter(modifier => modifier.checked).map(modifier => modifier.title), 
                    this.product.modifierGroups ? this.product.modifierGroups.map(group => group.modifiers.find(modifier => modifier.checked)?.title) : [], 
                    this.product.ingredients.filter(ingredient => ingredient.checked || ingredient.quantity > 0).map(ingredient => {
                        if (this.entity?.multiple_ingridients) {
                            return `${ingredient?.quantity}x ${ingredient.title}`
                        } else {
                            return ingredient.title;
                        }
                    }),
                    ingredientGroupsDescription?.flat()
                ).join(' • ');

                const modifiers = [
                    ...this.product.modifiers.filter(modifier => modifier.checked),
                    ...(this.product.modifierGroups ? this.product.modifierGroups.map(group => {
                        return group.modifiers.find(modifier => modifier.checked);
                    }) : [])
                ];

                const ingredients = [
                    ...this.product.ingredients.filter(ingredient => ingredient.checked || ingredient.quantity > 0),
                    ...(this.product.ingredientGroups ? this.product.ingredientGroups.map(group => {
                        return group.ingredients.filter(ingredient => ingredient.checked || ingredient.quantity > 0);
                    })?.flat() : [])
                ];
                const cartItem = {
                    entity_type_code: 'restaurant',
                    entity_code: this.entity?.code,
                    categories_page: !!this.entity?.categories_page,
                    entity_title: this.entity?.title,
                    restaurant_id: this.product.restaurant_id,
                    dish_id: this.product.id,
                    id: this.product.id,
                    picture: this.product.picture,
                    title: this.product.title,
                    description,
                    price: this.product.price,
                    old_price: this.product.old_price,
                    item_price: this.item_price,
                    old_item_price: this.old_item_price ? this.old_item_price : null,
                    modifiers,
                    ingredients,
                    quantity: +this.quantity,
                    discount: this.product.discount,
                    promotion_id: this.product.promotion_id,
                    weight_limit: this.entity.weight_limit,
                    weight_limit_description: this.entity.weight_limit_description,

                    weight: this.product.weight || 0,
                    calories: this.product.calories || 0,
                    carbohydrates:this.product.carbohydrates || 0,
                    fat:this.product.fat || 0,
                    protein:this.product.protein || 0,
                };
            
                addItemToCart({ 
                    orderType: ORDER_TYPE.RESTAURANT_DELIVERY, 
                    paymentType, 
                    cartItem
                }, async () => {
                    await store.dispatch('cart/recalculateDeliveryPrice');
                    this.closeSheet();
                });

            }
        },
    };
</script>
<style lang="scss">
.nutrition-facts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
}
.custom-sheet-product__close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    background-image: url('../../static/close-button.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px 11px;
}


.nutrition-fact {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    flex-grow: 1;
}
.new-line {
    display: block;
}
.product_details_calories {
    line-height: 12px;
    font-size: 10px;
    color: #9599A4;
}
.right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.restaurant-product-popup-page__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;

    .stepper-button, .stepper-button-minus, .stepper-button-plus, .stepper-input-wrap, .stepper-value, .stepper .stepper-input-wrap input {
    width: 30px;
    // font-size: 13px;
    line-height: 16px;
    color: #232321;
}
}

.stepper-container {
    width: 90px; /* Уменьшение ширины контейнера stepper, настраиваемый процент */
}

.stepper-small {
    width: 90px;
}
.restaurant-product-popup {
    height: calc(100vh - 50px - constant(safe-area-inset-top));
    height: calc(100vh - 50px - env(safe-area-inset-top));
    box-shadow: 0px 0px 12px 0px #0000001F;

    .hr-line {
        margin: 16px 0;
    }

    .stepper-button, .stepper-button-minus, .stepper-button-plus,
    .stepper-input-wrap, 
    .stepper .stepper-input-wrap input, .stepper-value {
        font-size: 15px;
        line-height: 18px;
        color: #232321;
    }
}
.restaurant-product-popup__container {
    height: calc(100vh - 120px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 120px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
.restaurant-product-popup__picture__wrapper {
    margin: -20px -20px 0 -20px;
    overflow: hidden;
}
.restaurant-product-popup__picture {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../static/empty-img.svg');
}
.restaurant-product-popup__discount {
    position: absolute;
    top: 7px; 
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    border: 2px solid #FFFFFF;
    border-radius: 32px;
}
.restaurant-product-popup__content {
    height: calc(100vh - 370px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 370px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    overflow: hidden;

    & > div {
        height: 100%;
        padding: 15px 0;
        overflow-y: auto;
    }
}
.restaurant-product-popup__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #323232;
}
.restaurant-product-popup__price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3px 0 0 20px;
    
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
    color: #323232;
    
    > span {
        white-space: nowrap;
    }
    > span.restaurant-product-popup__weight {
        white-space: nowrap;
        padding-top: 10px;

        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #323232;
        opacity: .5;
    }
}

.restaurant-product-popup__prices {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;
    width: 105px;
    
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #929292;
    
    > span {
        white-space: nowrap;
    }
    
    &__start {
        justify-content: flex-start;
    }
}
.restaurant-product-popup__old-price {
    position: relative;
    
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    text-decoration: line-through;

    color: #929292;

    > span {
        white-space: nowrap;
    }
    
    // &__line {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: url('../../static/line-oblique.svg') center center no-repeat;
    //     background-size: 100% 100%;
    // }
}
.restaurant-product-popup__description {
    margin-top: 10px;
    
    font-weight: normal;
    font-size: 15px;
    word-break: break-word;
    line-height: 140%;
    color: #232321;

    &__item {
        display: block;
        margin-top: 10px;

        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        color: #32323280;

        & > b {
            color: #323232;
            font-weight: 400;
        }
    }
    
    &__numbers {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: -10px;
    
        &__item {
            padding: 8px 16px;
            border-radius: 10px;
            border: 1px solid #E5E6E9;
            margin-top: 10px;

            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: center;
            color: #32323280;

            > span {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: #323232;
            }
        }
    }
}
.restaurant-product-popup__add-to-cart {
    display: flex;
    height: calc(constant(safe-area-inset-bottom) + 70px);
    height: calc(env(safe-area-inset-bottom) + 70px);
    padding: 0 20px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px -11px 41px 0px #0000000F;
    margin: 0 -20px -20px -20px;

    .stepper {
        height: 43px;
        background: none;

        .stepper-button-minus,
        .stepper-button-plus {
            width: 43px;
            height: 43px;
            background: #F0F2F3;
            border-radius: 10px;
            
        }
        .stepper-button-minus {
            &:after {
                background-color: #C8C8C8;
                width: 8px;
                height: 1px;
            }
        }
        .stepper-button-plus {
            &:before,
            &:after {
                background-color: #FF7E8A;
            }
            &:before {
                width: 1px;
                height: 8px;
            }
            &:after {
                width: 8px;
                height: 1px;
            }
        }
    }
}
.restaurant-product-popup__add-to-cart__stepper {
    background: #E7E7E7;
    border-radius: 6px;
}
.restaurant-product-popup__add-to-cart__button {
    width: calc(100% - 140px);
    max-width: 200px;
    justify-content: space-between;
}

.restaurant-product-popup__list {
    margin: 0;
    
    ul {
        background: none;
    }
    ul:before,
    ul:after {
        display: none;
    }

    .item-content {
        width: 100%;
    }
    .item-inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0 !important;
    }

    &__title {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #323232;
    }

    &__label {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #323232;
    }
    
    &__price {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #32323280;
        white-space: nowrap;
    }

    &__one {
        margin-right: auto;
    }

    label.item-checkbox input[type="checkbox"]:checked ~ .item-inner .restaurant-product-popup__list__price {
        color: #FF7E8A;
    }
}
</style>
